<template>

  <div>

    <!-- Table Container Card -->
    <b-overlay
      id="overlay-background"
      :show="loading"
      variant="blur"
      :opacity="0.5"
      blur="2px"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <label>Filter By seller</label>
                <v-select
                  v-model="filterByAdminId"
                  :options="filterSellerOptions"
                  :loading="loading"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>

                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <CollectionSelect
                ref="branchCollection"
                tablename="branch"
                label="Filter By branch"
                @selectedData="handleFilterByBranch"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <label>Filter By customer</label>
              <customer-select
                class="mb-0"
                @selectedData="onCustomerSelect"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <label>Cost method</label>
                <v-select
                  v-model="costMethodValue"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="costMethod"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group>
                <label>Tax choice</label>
                <v-select
                  v-model="taxChoiceValue"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="taxChoice"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-button @click="onReset">
                Reset
              </b-button>
              <b-button
                variant="gradient-success"
                class="ml-1"
                @click="exportToExcel"
              >
                Export to excel
              </b-button>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refItemListTable"
          v-model="saleListCopy"
          :per-page="7"
          :items="fetchItems"
          responsive
          :fields="tableColumns"
          :busy="loading"
          primary-key="index"
          :sort-by.sync="sortBy"
          striped
          :show-empty="!loading"
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(name)="data">
            <span>
              {{ data.item.name || '' }}
            </span>
          </template>

          <template #cell(customer)="data">
            <span>
              {{ data.item.saleModel.customerName || '' }}
            </span>
          </template>
          <template #cell(qty)="data">
            <span>
              {{ data.item.items.qty || '' }}
            </span>
          </template>
          <template #cell(sold)="data">
            <span :class="`text-${buildPaymentStatusColor(data.item.saleModel.creditStatus)}`">
              {{ data.item.items.amount || '' }}
            </span>
          </template>

          <template #cell(total)="data">
            <span>
              {{ Number(data.item.items.amount * data.item.items.qty) | formatAmount }}
            </span>
          </template>

          <template #cell(purchasePrice)="data">
            <span>
              {{ data.item.totalCost | formatAmount }}
            </span>
          </template>
          <template #cell(profit)="data">
            <span>
              {{ data.item.profit | formatAmount }}
            </span>
          </template>

          <template #cell(soldBy)="data">
            <span>
              {{ buildUserRoleText(data.item.saleModel.adminRole) }} {{ data.item.saleModel.adminName }}
            </span>
          </template>

          <template #cell(branch)="data">
            <span>
              {{ data.item.saleModel.branchName }}
            </span>
          </template>
          <template #cell(date)="data">
            <span>
              {{ new Date(data.item.saleModel.createdAt).toLocaleString() }}
            </span>
          </template><template #cell(generalNote)="data">
            <span>
              {{ data.item.saleModel.note ? data.item.saleModel.note : '' }}
            </span>
          </template>
          <template #cell(customIdentifierNote)="data">
            <span>
              {{ isDefAndNotEmpty(data.item.items.customItemIdentifer)
                ? data.item.items.customItemIdentifer
                : '' }}
            </span>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ from }} to {{ to }} of {{ of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-overlay>
    <hr class="invoice-spacing">
    <ExpenseList
      ref="expenseListRef"
      :loading-expense="loadingExpense"
      :expense-list="expenseList"
    />

    <hr class="invoice-spacing">

    <b-card>
      <b-row>

        <b-col
          lg="4"
          md="5"
        >

          <div class="card-transaction">

            <div class="transaction-item">
              <h3 class="font-weight-bolder transaction-title text-black">
                Purchase: {{ getPurchase | formatAmount }}
              </h3>
            </div>
            <div class="transaction-item">
              <h3 class="font-weight-bolder transaction-title text-primary">
                Sell: {{ getSell | formatAmount }}
              </h3>
            </div>
            <div class="transaction-item">
              <h3 class="font-weight-bolder transaction-title text-success">
                Profit: {{ getProfit | formatAmount }}
              </h3>
            </div>
            <div class="transaction-item">
              <h3 class="font-weight-bolder transaction-title text-warning">
                Due: {{ getUnpaid | formatAmount }}
              </h3>
            </div>
            <div
              v-if="filterIf"
              class="transaction-item"
            >
              <h3 class="font-weight-bolder transaction-title text-danger">
                Expense: {{ calculateExpenses | formatAmount }}
              </h3>
            </div>
            <div
              v-if="filterIf"
              class="transaction-item"
            >
              <h3 class="font-weight-bolder transaction-title text-danger">
                Loss: {{ getLoss | formatAmount }}
              </h3>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
  BOverlay,
  BPagination, BFormGroup, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import {
  historyTypeField, buildUserRoleText, branchTypeField, creditStatusField, saleTypeField, TaxFilter, saleModelField, saleItemModelField, itemHistoriesModelField,
} from '@/api/field_model'
import { Ajax, getApiData, RouteConst } from '@/libs/ajax'
import ExpenseList from './components/ExpenseList.vue'
import CollectionSelect from '../collections/CollectionSelect.vue'
import { isDefAndNotEmpty, kFakeNumber, paginateArray } from '../../assets/helpers'
import {
  buildPaymentStatusColor,
  buildPaymentStatusText,
} from '../sale/saleHelper'
import CustomerSelect from '../collections/CustomerSelect.vue'

const defaultFilterByAdminId = {
  label: 'All',
  value: kFakeNumber,
}

const costMethod = [
  { title: 'FIFO/LIFO', value: 0 }, { title: 'Average', value: 1 },
]
const taxChoice = [
  { title: 'All', value: TaxFilter.all }, { title: 'Including Tax', value: TaxFilter.includeTax }, { title: 'Exclude Tax', value: TaxFilter.excludeTax },
]

export default {
  components: {

    BCard,
    BRow,
    BOverlay,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCol,
    BTable,
    BFormGroup,
    BPagination,

    vSelect,
    CollectionSelect,
    ExpenseList,
    CustomerSelect,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatAmount: value => {
      if (!value) {
        return ''
      }
      return Number(value.toFixed(2)).toLocaleString()
    },
  },
  data() {
    return {
      currentPage: 1,
      branchTypeField,
      loadingExpense: true,
      expenseList: [],
      isDefAndNotEmpty,
      buildPaymentStatusColor,
      buildPaymentStatusText,
      buildUserRoleText,
      historyTypeField,
      tableColumns: [
        { key: 'name', label: 'Item name', thStyle: { width: '20%' } },
        { key: 'customer', label: 'Customer' },
        { key: 'qty', label: 'Total Qty' },
        { key: 'sold', label: 'Selling price' },
        { key: 'total', label: 'Total' },
        { key: 'purchasePrice', label: 'Total Purchase' },
        { key: 'profit', label: 'Profit' },
        { key: 'soldBy', label: 'Sold by' },
        { key: 'branch', label: 'Branch' },
        { key: 'date', label: 'Date' },
        { key: 'generalNote', label: 'General note' },
        { key: 'customIdentifierNote', label: 'Custom Identifier note' },
      ],
      sortBy: 'id',
      filterByAdminId: { ...defaultFilterByAdminId },
      filterByBranchId: kFakeNumber,
      filterByCustomerId: kFakeNumber,
      isSortDirDesc: true,
      perPage: 7,
      isIncludingTax: true,
      loading: false,
      filterSellerOptions: [],
      saleListCopy: [],
      purchaseModelDataList: [],
      reportModelList: [],
      costMethodValue: costMethod[0],
      taxChoiceValue: taxChoice[0],
      costMethod,
      taxChoice,
    }
  },
  computed: {
    isAverage() {
      return this.costMethodValue.value === 1
    },
    taxFilter() {
      return this.taxChoiceValue.value
    },
    getLoss() {
      const loss = Number(this.getProfit - this.calculateExpenses)
      return this.calculateExpenses !== 0 && loss < 0 ? (loss) : 0
    },
    calculateExpenses() {
      let expenses = 0
      // this.expenseModelList().forEach(element => {
      //   expenses += Number(element.amount)
      // })
      expenses = this.expenseModelList().reduce((e, c) => (e + Number(c.amount.split(' ').join(''))), 0)
      return expenses || 0
    },
    getUnpaid() {
      // let unpaid = 0
      // this.getActualDataPurchaseModel.forEach(element => {
      //   if (element.creditStatus !== creditStatusField.payed
      //     && element.type === saleTypeField.credit) {
      //     unpaid += element.unpaidAmount
      //   }
      // })
      // return unpaid

      let unpaid = 0
      const saleModels = []

      const isExistF = s => {
        let isExist = false
        // eslint-disable-next-line no-restricted-syntax
        for (const element of saleModels) {
          if (element.id === s.id) {
            isExist = true
          }
        }
        return isExist
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const element of this.getActualDataFn()) {
        if (!isExistF(element.saleModel)) {
          saleModels.push(element.saleModel)
        }
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const element of saleModels) {
        if (element.type === saleTypeField.credit
          && element[saleModelField.creditStatus] !== creditStatusField.payed) {
          unpaid += (element[saleModelField.unpaidAmount] ?? 0)
        }
      }
      return unpaid
    },
    getProfit() {
      return Number(this.getSell - this.getPurchase)
    },
    getSell() {
      let sell = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const element of this.getActualDataFn()) {
        const element2 = element.items
        const sellingPrice = Number(element2.amount)
        const isWithTax = sellingPrice !== 0 && element.saleModel[saleModelField.taxPercentage] !== 0
        const sellingPriceWithTax = isWithTax
          ? sellingPrice
              + (sellingPrice * ((element.saleModel[saleModelField.taxPercentage] || 0) / 100))
          : sellingPrice

        sell += (element.saleModel[saleModelField.includeVat]
                  && ((this.taxFilter === TaxFilter.all && this.isIncludingTax)
                      || this.taxFilter === TaxFilter.includeTax)
          ? sellingPriceWithTax
          : sellingPrice)
          * element2.qty
      }
      // this.getActualDataPurchaseModel.forEach(element => {
      //   element.items.forEach(element2 => {
      //     sell
      //         += Number(element2.amount * element2.qty)
      //   })
      // })
      return sell
    },
    getPurchase() {
      const purchase = this.getActualDataFn().reduce((previous, current) => previous + current.totalCost, 0)
      return purchase
    },
    total() {
      const t = this.getActualDataFn().length
      return t
    },
    getActualData() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return paginateArray(this.getActualDataFn(), this.perPage, this.currentPage)
    },
    filterIfNot() {
      return this.filterByAdminId.value !== kFakeNumber || this.filterByBranchId !== kFakeNumber || this.filterByCustomerId !== kFakeNumber
    },

    filterIf() {
      return this.filterByAdminId === kFakeNumber || this.filterByBranchId === kFakeNumber || this.filterByCustomerId === kFakeNumber
    },
    from() {
      return this.perPage * (this.currentPage - 1) + (this.getActualDataFn().length ? 1 : 0)
    },
    to() {
      return this.perPage * (this.currentPage - 1) + this.getActualDataFn().length
    },
    of() {
      return this.total
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'filterByAdminId.value': function () {
      // console.log(v)
      this.currentPage = 1
      this.$refs.refItemListTable.localItems = this.getActualData
    },
    filterByCustomerId() {
      // console.log(v)
      this.currentPage = 1
      this.$refs.refItemListTable.localItems = this.getActualData
    },
    currentPage() {
      this.$refs.refItemListTable.localItems = this.getActualData
    },
    filterByBranchId() {
      this.currentPage = 1
      this.$refs.refItemListTable.localItems = this.getActualData
    },
  },
  methods: {
    expenseModelList() {
      // return this.$refs.expenseListRef ? this.$refs.expenseListRef.expenseList || [] : []
      return this.expenseList
    },
    exportToExcel() {
      import('@/libs/Export2Excel').then(excel => {
        const headerTitle = ['No', 'Item name', 'Customer', 'Total Qty', 'Selling price', 'Total', 'Total Cost', 'Profit', 'Sold By', 'Branch', 'Date', 'General note', 'Custom Identifier note']

        const data = this.formatJson(headerTitle, this.getActualDataFn())
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'business_report',
          autoWidth: true,
          bookType: 'xlsx',
        })
      })
    },

    formatJson(filterVal, jsonData) {
      // console.log(jsonData)
      let i = 0
      return jsonData.map(v => filterVal.map(j => {
        switch (j) {
          case 'No':
            i += 1
            return i
          case 'Item name':
            return v.name || 'N/A'
          case 'Customer':
            return v.saleModel.customerName || 'N/A'
          case 'Total Qty':
            return v.itemQty || ''
          case 'Selling price':
            return v.items.amount || ''
          case 'Total':
            return Number(v.items.amount * v.items.qty)
          case 'Total Cost':
            return v.totalCost || ''
          case 'Profit':
            return v.profit || ''
          case 'Sold By':
            return `${buildUserRoleText(v.saleModel.adminRole)} ${v.saleModel.adminName || 'N/A'}`
          case 'Branch':
            return v.saleModel.branchName || 'N/A'
          case 'Date':
            return new Date(v.saleModel.createdAt).toLocaleString()
          case 'General note':
            return v.saleModel.note || ''
          case 'Custom Identifier note':
            return v.items.customItemIdentifer || ''

          default:
            return v[j]
        }
      }))
    },
    onReset() {
      this.filterByAdminId = { ...defaultFilterByAdminId }
      this.filterByBranchId = kFakeNumber
      this.filterByCustomerId = kFakeNumber
      // eslint-disable-next-line prefer-destructuring
      this.costMethodValue = costMethod[0]
      // eslint-disable-next-line prefer-destructuring
      this.taxChoiceValue = taxChoice[0]
      this.$refs.branchCollection.$refs.selectRef.clearSelection()
    },
    filterData() {
      const soldByList = []
      const { filterByAdminId, filterByBranchId, filterByCustomerId } = this

      const filterByAdminIdP = filterByAdminId.value
      // eslint-disable-next-line no-restricted-syntax
      for (const element of this.purchaseModelDataList) {
      // seller, branch, customer
        if (filterByAdminIdP !== kFakeNumber
          && filterByBranchId !== kFakeNumber
          && filterByCustomerId !== kFakeNumber) {
          if (element.adminId === filterByAdminIdP
            && element.branchId === filterByBranchId
            && element.customerId === filterByCustomerId) {
            soldByList.push(element)
          }
        // seller, branch
        } else if (filterByAdminIdP !== kFakeNumber
          && filterByBranchId !== kFakeNumber) {
          if (element.adminId === filterByAdminIdP
            && element.branchId === filterByBranchId) {
            soldByList.push(element)
          }
        // seller, customer
        } else if (filterByAdminIdP !== kFakeNumber
          && filterByCustomerId !== kFakeNumber) {
          if (element.adminId === filterByAdminIdP
            && element.customerId === filterByCustomerId) {
            soldByList.push(element)
          }
        // branch, customer
        } else if (filterByBranchId !== kFakeNumber
          && filterByCustomerId !== kFakeNumber) {
          if (element.branchId === filterByBranchId
            && element.customerId === filterByCustomerId) {
            soldByList.push(element)
          }
        } else if (filterByAdminIdP !== kFakeNumber) {
          if (element.adminId === filterByAdminIdP) {
            soldByList.push(element)
          }
        } else if (filterByBranchId !== kFakeNumber) {
          if (element.branchId === filterByBranchId) {
            soldByList.push(element)
          }
        } else if (filterByCustomerId !== kFakeNumber) {
          if (element.customerId === filterByCustomerId) {
            soldByList.push(element)
          }
        }
      }
      return soldByList
    },
    getAveragePurchasePrice(purchaseItems) {
      const purchasePricePrivate = (purchaseItems[saleItemModelField.average] || 0) * purchaseItems.qty

      return purchasePricePrivate
    },
    getFifoPurchasePrice(purchaseItems) {
      let purchasePricePrivate = 0
      if (purchaseItems.saleHistories.length <= 0) {
        return this.getAveragePurchasePrice(purchaseItems)
      }
      purchasePricePrivate = purchaseItems.saleHistories.reduce((p, c) => (p + ((c[itemHistoriesModelField.purchasePrice] || 0) * (c.qty || 0))), 0)

      return purchasePricePrivate
    },
    getProfitForOneItem(element2, totalCost) {
      const totalSellingPrice = Number(element2[saleItemModelField.amount] || 0) * element2.qty

      return totalSellingPrice - totalCost
    },
    getBusinessReportModel(element, element2, i) {
      const totalCost = Number(this.isAverage === true
        ? this.getAveragePurchasePrice(element2)
        : this.getFifoPurchasePrice(element2))

      return {
        index: i,
        name: element2.item.name,
        items: element2,
        id: element2.itemId,
        itemQty: element2.qty || 0,
        totalCost,
        profit: this.getProfitForOneItem(element2, totalCost),
        // eslint-disable-next-line no-nested-ternary
        purchasePrice: element2.purchasePrice ? element2.purchasePrice : (element2.item ? element2.item.purchasePrice : 0),
        saleModel: element,
      }
    },
    getActualDataFn() {
      let reportModelListPrivate = []
      if (this.filterIfNot) {
        let i = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const element of this.filterData()) {
          if (isDefAndNotEmpty(element.items)) {
            // eslint-disable-next-line no-restricted-syntax
            for (const element2 of element.items) {
              i += 1
              reportModelListPrivate.push(this.getBusinessReportModel(element, element2, i))
            }
          }
        }
      } else {
        reportModelListPrivate = this.reportModelList.map(e => this.getBusinessReportModel(e.saleModel, e.items))
      }
      // eslint-disable-next-line no-nested-ternary
      const newData = this.taxFilter === TaxFilter.all
        ? reportModelListPrivate
        : this.taxFilter === TaxFilter.includeTax
          ? reportModelListPrivate
            .filter(element => element.saleModel[saleModelField.includeVat])
          : reportModelListPrivate
            .filter(element => !element.saleModel[saleModelField.includeVat])
      return newData
    },
    handleFilterByBranch(v) {
      this.filterByBranchId = v.id
    },
    onCustomerSelect(customer) {
      this.filterByCustomerId = customer.id
    },
    handleSellerList() {
      const { purchaseModelDataList } = this
      const filterBySellerList = []

      if (isDefAndNotEmpty(purchaseModelDataList)) {
        for (let i = 0; i < purchaseModelDataList.length; i += 1) {
          const element = purchaseModelDataList[i]
          const filterBySellerModel = {
            value: element.adminId,
            label: element.adminName,
          }

          filterBySellerList.push(filterBySellerModel)
        }
      }

      this.filterSellerOptions = filterBySellerList.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i)
    },
    fetchItems(ctx, callback) {
      this.loading = true
      this.loadingExpense = true
      Ajax(RouteConst.getAllPurchaseByDateUrl, {
        body: {
          startDateTime: this.$route.query.startDate,
          endDateTime: this.$route.query.endDate,
          chooseReportDateType: this.$route.query.chooseReportDateType,
          includingExpense: true,
        },
      }).then(res => {
        this.loadingExpense = false
        this.loading = false
        const originalResult = getApiData(res)
        this.purchaseModelDataList = originalResult.saleList
        this.expenseList = originalResult.expenses
        this.$refs.expenseListRef.expenseListt = originalResult.expenses
        this.handleSellerList()
        // eslint-disable-next-line no-unused-vars
        let i = 0
        originalResult.saleList.forEach(element => {
          // eslint-disable-next-line no-param-reassign
          if (isDefAndNotEmpty(element.items)) {
            for (let index = 0; index < element.items.length; index += 1) {
              i += 1
              const element2 = element.items[index]
              this.reportModelList.push(this.getBusinessReportModel(element, element2, i))
            }
          }
        })
        callback(paginateArray(this.reportModelList, this.perPage, this.currentPage))
      }).catch(error => {
        console.log('error', error)
        this.loading = false
        this.loadingExpense = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching sale list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          timeout: 4000,
          position: 'bottom-right',
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  min-width: 240px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
