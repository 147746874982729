import { companyModelField, userModelField, userTypeRole } from '@/api/field_model'
import { kFakeNumberString } from '@/assets/helpers'
import UserAllowed from '@/auth/userAllowed'

const AuthMixin = {

  computed: {
    companyInfo() {
      return this.$store.state.auth.companyInfo
    },
    minStockQty() {
      return this.companyInfo ? this.companyInfo.minStockQty : 0
    },
    userData() {
      return this.$store.state.auth.AppActiveUser
    },
    isAdmin() {
      return this.userData ? this.userData.isAdmin === true : false
    },
    isStockKeeper() {
      return this.userData ? this.userData[userModelField.role] === userTypeRole.stockKeeper : false
    },
    isSales() {
      return this.userData ? this.userData[userModelField.role] === userTypeRole.sales : false
    },
    multipleSalesBranchId() {
      // eslint-disable-next-line no-nested-ternary
      return !this.isAdmin && this.userData
        ? (this.userData[userModelField.multipleSalesBranchId] === kFakeNumberString
              || this.userData[userModelField.multipleSalesBranchId] == null
          ? []
          : this.userData[userModelField.multipleSalesBranchId].split(','))
        : []
    },
    isAllowedToSeeAllBranches() {
      return this.isAdmin || this.multipleSalesBranchId.length >= 1 || this.multipleSalesBranchId.length <= 0
    },
    isMultipleBranchMode() {
      return this.companyInfo[companyModelField.multipleBranchMode]
    },
    userAllowedList() {
      return this.userData ? this.userData.allowed.split(',') : []
    },
    userAllowedClass() {
      return new UserAllowed()
    },
    legalBranch() {
      return !this.isAdmin && this.multipleSalesBranchId.length === 1
        ? this.multipleSalesBranchId[0]
        : null
    },
    taxLabelWithPercentage() {
      return `${this.companyInfo[companyModelField.taxField]} [${this.companyInfo[companyModelField.taxPercentage]}%]`
    },
    legalUserId() {
      return !this.isAdmin
        ? this.userData.id
        : null
    },
  },
  methods: {
    calcVatFn(v) {
      const taxPercentage = this.companyInfo[companyModelField.taxPercentage]
      return taxPercentage === 0 ? Number(v) : Number(v * (taxPercentage / 100))
    },
  },

}

export default AuthMixin
