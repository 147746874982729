<template>
  <b-sidebar
    v-if="show"
    id="add-customer-sidebar"
    ref="addCustomerSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Customer
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="Customer name"
            rules="required"
          >

            <b-form-group>
              <b-form-input
                id="customer-name"
                v-model="customerModel.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Customer name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group>
            <b-form-input
              id="customer-phone"
              v-model="customerModel.phone"
              trim
              placeholder="Customer Phone number"
            />

          </b-form-group>

          <b-form-group>
            <b-form-input
              id="company"
              v-model="customerModel.company"
              trim
              placeholder="Company name"
            />
          </b-form-group>

          <b-form-group>
            <b-form-input
              id="address"
              v-model="customerModel.address"
              trim
              placeholder="Company Address"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              class="mr-2"
              :disabled="loading"
              type="submit"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              :disabled="loading"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import {
  BSidebar, BForm, BFormGroup, BFormInvalidFeedback, BButton, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { defaultCustomerModal } from '@/api/default_models'
import { Ajax, RouteConst, isApiSuccess } from '@/libs/ajax'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,

    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      show: true,
      loading: false,
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true

      const res = await new Ajax(RouteConst.addNewCustomerToDBUrl, {
        body: {
          customerModel: this.customerModel,
        },
      })
      this.loading = false
      if (isApiSuccess(res)) {
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
        this.$emit('refresh')
        this.$refs.addCustomerSidebar.hide()
      }
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const customerModel = ref(JSON.parse(JSON.stringify(defaultCustomerModal)))

    const resetCustomerModel = () => {
      customerModel.value = JSON.parse(JSON.stringify(defaultCustomerModal))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCustomerModel)

    return {
      customerModel,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
