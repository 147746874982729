<template>
  <!-- Expense -->
  <b-overlay
    id="overlay-expense-background"
    :show="loadingExpense"
    variant="blur"
    :opacity="0.5"
    blur="2px"
    rounded="sm"
  >
    <b-card
      no-body
      class="mb-0"
    >

      <b-card-header>
        <b-card-title>Expense</b-card-title>

      </b-card-header>

      <b-card-body style="padding:0px;">

        <b-table
          ref="refItemListTable"
          v-model="expenseListt"
          :items="expenseList"
          :per-page="7"
          class="position-relative"
          responsive
          :fields="tableColumns"
          :busy="loadingExpense"
          primary-key="id"
          striped
          :show-empty="expenseListt.length === 0"
          empty-text="No expense found"
        />
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ from }} to {{ to }} of {{ of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
  BOverlay, BCardTitle, BCardBody,
  BPagination,
  BCardHeader,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { paginateArray } from '@/assets/helpers'

export default {
  components: {

    BCard,
    BRow,
    BOverlay,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCol,
    BCardHeader,
    BTable,
    BCardBody,
    BPagination,

  },
  props: {
    loadingExpense: {
      type: Boolean,
      default: true,
    },
    expenseList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentPage: 1,
      expenseListt: [],
      loading: false,
      perPage: 7,
      actualTotal: 0,
      tableColumns: [
        { key: 'payee' },
        { key: 'amount' },
        { key: 'date' },
      ],
    }
  },
  computed: {
    total() {
      console.log('computed', this.expenseListt)
      return this.expenseListt.length
    },
    getActualData() {
      return paginateArray(this.expenseListt, this.perPage, this.currentPage)
    },
    from() {
      return this.perPage * (this.currentPage - 1) + (this.expenseListt.length ? 1 : 0)
    },
    to() {
      return this.perPage * (this.currentPage - 1) + this.expenseListt.length
    },
    of() {
      return this.total
    },
  },
  watch: {
    currentPage() {
      console.log('watch', this.$refs.refItemListTable.localItems)
      this.$refs.refItemListTable.localItems = this.getActualData
    },
  },
  methods: {
    // fetchItems(ctx, callback) {
    //   this.loading = true

    //   new Ajax(RouteConst.getAllPurchaseByDateUrl, {
    //     body: {
    //       startDateTime: this.$route.query.startDate,
    //       endDateTime: this.$route.query.endDate,
    //       chooseReportDateType: this.$route.query.chooseReportDateType,
    //     },
    //   }).then(res => {
    //     this.loading = false
    //     const originalResult = getApiData(res)

    //     callback(originalResult)
    //   })
    //     .catch(() => {
    //       // console.log('error', error)
    //       this.loading = false
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Error fetching expense list, Refresh',
    //           icon: 'AlertTriangleIcon',
    //           variant: 'danger',
    //         },
    //       },
    //       {
    //         timeout: 4000,
    //         position: 'bottom-right',
    //       })
    //     })
    // },
  },
}
</script>

<style scoped>

</style>
