/* eslint-disable comma-dangle */
import {
  creditStatusField, itemHistoriesModelField, itemStatus, saleTypeField
} from './field_model'

const defaultPurchaseModel = {
  items: [], // default
  createdAt: null,
  updatedAt: null,
  customerId: null,
  adminId: null,
  adminName: null,
  adminRole: null,
  customerName: null,
  note: '',
  customerPhoneNo: null,
  type: {
    label: 'Cash (Full payment)',
    value: saleTypeField.cash,
  }, // default
  returnDate: null,
  branchId: null,
  branchName: null,
  branchType: null,
  unpaidAmount: null,
  creditStatus: creditStatusField.payed, // default
  totalAmount: null,
  storeTotal: null,
  stockTotal: null,
  creditPayedAmount: 0, // default
  includeVat: true, // default
  chequeNo: null,
  creditPaymentAmountHistory: [], // default
}

const defaultCustomerModal = {
  name: null,
  phone: null,
  company: null,
  address: null,
}

const defaultTaxModel = {
  rate: null,
  name: '',
}

const defaultPurchaseItems = {
  originalId: null,
  itemId: null,
  qty: null,
  customItemIdentifer: null,
  branchType: null,
  storeId: null,
  amount: null,
  itemModel: null,
}

const defaultItemModelVal = {
  name: '',
  partNo: '',
  barcode: '',
  unitPrice: null,
  image: '',
  location: '',
  purchasePrice: null,
  code: '',
  invoiceDate: '',
  branchId: null,
  invoiceNo: '',
  lowStock: null,
  groupp: '',
  mark: '',
  qty: null,
  expireDate: null,
  description: '',
  manufacturer: '',
  manufacturerDate: '',
  brand: '',
  measurement: '',
  minPrice: null,
  maxPrice: null,
  status: {
    title: 'Available',
    value: itemStatus.available,
  },
}

const collectionModelField = {
  name: 'name',
}

const collectionLocalSql = {
  groupCollectionTable: 'groupcollection',
  markTable: 'mark',
  brandTable: 'brand',
  measurementTable: 'measurement',
}

const defaultItemHistoryModel = {
  [itemHistoriesModelField.qty]: null,
  [itemHistoriesModelField.purchasePrice]: null,
  [itemHistoriesModelField.expireDate]: null,
  [itemHistoriesModelField.note]: '',
  suppler: {},
}

export {
  defaultPurchaseModel,
  defaultCustomerModal,
  defaultPurchaseItems,
  defaultItemModelVal,
  defaultTaxModel,
  collectionModelField,
  defaultItemHistoryModel,
  collectionLocalSql,
}
