const { creditStatusField, saleTypeField } = require('@/api/field_model')

const buildPaymentStatusColor = value => {
  switch (value) {
    case creditStatusField.unPayed:
      return 'warning'
    case creditStatusField.advance:
      return 'primary'
    default:
      return 'success'
  }
}

const buildPaymentStatusText = value => {
  switch (value) {
    case creditStatusField.unPayed:
      return 'UNPAID'
    case creditStatusField.advance:
      return 'ADVANCE PAYMENT'
    default:
      return 'FULL PAYMENT'
  }
}

const buildPaymentTypeText = value => {
  switch (value) {
    case saleTypeField.cash:
      return 'Cash'
    case saleTypeField.cheque:
      return 'Cheque'
    case saleTypeField.credit:
      return 'Credit'
    default:
      return ''
  }
}

export {
  buildPaymentStatusColor,
  buildPaymentStatusText,
  buildPaymentTypeText,
}
