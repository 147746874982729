<template>
  <b-form-group>
    <AddNewCustomer @refresh="onClick({force: true})" />

    <v-select
      ref="selectRef"
      v-model="selected"
      :filterable="false"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="paginated"
      :loading="loading"
      :clearable="false"
      placeholder="Select Customer"
      @search="query => search = query"
      @click.native="onClick({})"
    >
      <template #list-header>
        <li
          v-b-toggle.add-customer-sidebar
          class="add-new-client-header d-flex align-items-center my-50"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
          <span class="align-middle ml-25">Add New Customer</span>
        </li>
      </template>
      <li
        v-if="options >= limit"
        slot="list-footer"
        class="pagination"
      >
        <b-button
          variant="relief-secondary"
          :disabled="!hasPrevPage"
          @click="offset -= limit"
        >
          Prev
        </b-button>

        <b-button
          variant="relief-primary"
          :disabled="!hasNextPage"
          @click="offset += limit"
        >
          Next
        </b-button>
      </li>
    </v-select>

  </b-form-group>
</template>

<script>
import vSelect from 'vue-select'
import { heightFade } from '@core/directives/animations'
import {
  BFormGroup,
  BButton,
  VBToggle,
} from 'bootstrap-vue'
import {
  Ajax, RouteConst, getApiData, isApiSuccess,
} from '@/libs/ajax'
import AddNewCustomer from './AddNewCustomer.vue'

export default {
  components: {
    BFormGroup,
    BButton,
    vSelect,
    AddNewCustomer,
  },
  directives: {
    'height-fade': heightFade,
    'b-toggle': VBToggle,
  },

  data() {
    return {
      options: [],
      search: '',
      offset: 0,
      limit: 10,
      loading: false,
      selected: '',
    }
  },
  computed: {
    filtered() {
      return this.options.filter(item => item.name.includes(this.search))
    },
    paginated() {
      return this.filtered.slice(this.offset, this.limit + this.offset)
    },
    hasNextPage() {
      const nextOffset = this.offset + this.limit
      return Boolean(this.options.slice(nextOffset, this.limit + nextOffset).length)
    },
    hasPrevPage() {
      const prevOffset = this.offset - this.limit
      return Boolean(this.options.slice(prevOffset, this.limit + prevOffset).length)
    },
  },
  watch: {
    selected(v) {
      if (v) {
        this.$emit('selectedData', v)
      }
    },
  },

  methods: {
    onClick({ force = false }) {
      if (this.options.length <= 0 || force) {
        this.loading = true
        this.fetchOptions()
      }
    },
    async fetchOptions() {
      try {
        const res = await Ajax(RouteConst.getPaginatedCustomersUrl, { page: 1 })
        if (isApiSuccess(res)) {
          this.options = getApiData(res).map(element => ({ title: element.name, ...element }))
        }
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
 .pagination {
    display: flex;
    margin: .25rem .25rem 0;
  }
  .pagination button {
    flex-grow: 1;
  }
  .pagination button:hover {
    cursor: pointer;
  }
#modal-prevent-closing {
  .form-control {
    text-align: center;
  }
}
</style>
